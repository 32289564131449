import * as React from "react";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";

const BlogPostTemplate = ({
  data: { markdownRemark: post, allMarkdownRemark },
  location,
}) => {
  const allPosts = allMarkdownRemark.nodes.filter(
    (allPostsItem) => allPostsItem.fields.slug !== post.fields.slug
  );
  const firstThreePosts = allPosts.slice(0, 3);
  return (
    <Layout
      location={location}
      seoTitle={post.frontmatter.metaTitle || post.frontmatter.title}
      seoDescription={
        post.frontmatter.metaDescription || post.frontmatter.description
      }
      seoKeywords={post.frontmatter.metaKeywords}
    >
      <main className="page-blog-post pt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <section className="blog-post-header">
                <div className="row">
                  <div className="col-lg-10 offset-lg-1">
                    <p className="blog-post-date">{post.frontmatter.date}</p>
                    <h1 className="blog-post-title">
                      {post.frontmatter.title}
                    </h1>
                  </div>
                </div>
              </section>
              <section className="blog-post-content">
                {post.frontmatter.featuredImage.childImageSharp.fluid && (
                  <div className="row">
                    <div className="col-12 mb-5">
                      <Img
                        fluid={
                          post.frontmatter.featuredImage.childImageSharp.fluid
                        }
                        className="img-fluid"
                        alt={post.frontmatter.title}
                      />
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-lg-10 offset-lg-1">
                    <div dangerouslySetInnerHTML={{ __html: post.html }} />
                  </div>
                </div>
              </section>
              <section className="blog-post-author-info-section">
                <div className="row">
                  <div className="col-lg-8 offset-lg-2">
                    <h5 className="blog-post-author-info-section-title">
                      Ten artykuł został napisany przez
                    </h5>
                    <div className="blog-post-author-info media">
                      <img
                        src="/images/blog-post-author/pawel.jpeg"
                        alt="avatar"
                        className="align-self-sm-center blog-post-author-avatar rounded"
                      />
                      <div className="media-body">
                        <div className="d-flex flex-wrap align-items-center">
                          <h6 className="mr-3 mb-0">Paweł Konowoł</h6>
                          <nav className="author-social-links">
                            <a
                              href="https://www.linkedin.com/in/pawelkonowol/"
                              target="_blank"
                              title="LinkedIn Paweł Konowoł"
                            >
                              <i className="fab fa-linkedin"></i>
                            </a>
                          </nav>
                        </div>
                        <p className="author-description">CEO</p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="related-posts-section mt-5">
                <h4 className="related-posts-section-title">
                  Najnowsze artykuły
                </h4>
                <div className="row">
                  {firstThreePosts.map((firstThreePostsItem) => (
                    <div className="col-md-4 related-post">
                      <div className="related-post-thumbnail-wrapper">
                        <Link
                          to={`/blog${firstThreePostsItem.fields.slug}`}
                          rel="prev"
                        >
                          <Img
                            fluid={
                              firstThreePostsItem.frontmatter.featuredImage
                                .childImageSharp.fluid
                            }
                            alt={firstThreePostsItem.frontmatter.title}
                          />
                        </Link>
                        <span className="blog-post-badge">Artykuł</span>
                      </div>
                      <p className="post-published-date">
                        {firstThreePostsItem.frontmatter.date}
                      </p>
                      <h4 className="post-tite">
                        {firstThreePostsItem.frontmatter.title}
                      </h4>
                    </div>
                  ))}
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!, $language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "DD MMM YYYY", locale: $language)
        description
        metaDescription
        metaTitle
        metaKeywords
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          date(formatString: "DD MMM YYYY", locale: $language)
          title
          description
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
